<template>
  <div class="container">
    <a-page-header :title="title" sub-title="操作即时生效，谨慎操作！" />

    <div class="main-content">
      <!-- TabBar图标管理-start -->
      <div class="main-content-header">
        <div class="item">
          <div class="title">
            <span class="title-1">TabBar图标管理</span>
            <span class="title-2">建议图片尺寸：81px x 81px</span>
          </div>
        </div>
        <div class="item">
          <a-button
            type="primary"
            @click="$jump({ path: '/clientMasterTabbarIcon' })"
          >
            编辑图标
          </a-button>
        </div>
      </div>
      <my-table
        :columns="columnsIcon"
        :data-source="tabbars"
        :loading="tabbarLoading"
        :pagination="false"
      >
        <template slot="icon1" slot-scope="record">
          <div class="icon" v-if="record.dicon">
            <img class="img" :src="record.dicon" alt="icon" />
          </div>
          <span v-else>-</span>
        </template>
        <template slot="icon2" slot-scope="record">
          <div class="icon" v-if="record.icon">
            <img class="img" :src="record.icon" alt="icon" />
          </div>
          <span v-else>-</span>
        </template>
      </my-table>
      <!-- TabBar图标管理-end -->

      <!-- 缺省语句-start -->
      <div class="main-content-header">
        <div class="item">
          <div class="title">
            <span class="title-1">缺省语句</span>
          </div>
        </div>
      </div>
      <my-table
        :columns="columnsDefault"
        :data-source="defaults"
        :loading="defaultLoading"
        :pagination="false"
      >
      </my-table>
      <!-- 缺省语句-end -->
    </div>

    <drawer :title="drawerTitle" :width="376" :visible="visible"> </drawer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import drawer from '../../components/drawer/index'
export default {
  data() {
    return {
      title: '',
      tabbars: [],
      defaults: [],
      columnsIcon: [
        // 图标
        {
          title: '序号',
          width: 60,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '图标名称',
          dataIndex: 'name',
          width: 200
        },
        {
          title: '未选中图标',
          width: 200,
          scopedSlots: { customRender: 'icon1' }
        },
        {
          title: '选中图标',
          scopedSlots: { customRender: 'icon2' }
        }
      ],
      columnsDefault: [
        // 缺省
        {
          title: '序号',
          width: 60,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '修改示例图',
          dataIndex: 'name',
          width: 200
        },
        {
          title: '文案内容',
          dataIndex: 'content'
        },
        {
          title: '操作',
          width: 100,
          fixed: 'right',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ],
      tabbarLoading: true,
      defaultLoading: true,
      drawerTitle: '',
      visible: false
    }
  },
  computed: {
    ...mapState(['qiniuData'])
  },
  components: {
    drawer
  },
  created() {
    this.title = this.$getPageTitle()
    this.getClientMasterTabBarIcon()
  },
  methods: {
    getClientMasterTabBarIcon() {
      // 获取用户端tabBar图标
      this.$axios.getClientMasterTabBarIcon().then((res) => {
        this.tabbars = res.data.data.list
        this.tabbarLoading = false
      })
    }
  }
}
</script>

<style scoped>
.icon {
  width: 40px;
}
</style>
